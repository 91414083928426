import { useEffect, useState } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { TextInputField } from "evergreen-ui";
import { LoadingButton } from "../../components/layout/loadbutton"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import axios from "../../backend/axios"
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup" 

const emailVerificationSchema = yup.object().shape({ 
  email: yup.string().email().required(),
  verificationCode: yup.string().required().min(1, "Email verifciation code is required")

})

const EmailVerificationPage = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const { verificationCode } = useState('')
  const [requestLoading, setRequestLoading] = useState(false)

  const methods = useForm({
    resolver: yupResolver(emailVerificationSchema)
  })

  useEffect(() => {}, [email, verificationCode])

  const verifyEmail = async data => {
    try {
      setRequestLoading(true)
      const response = await axios.post(
        '/verifyemail', data
      )
      setRequestLoading(false)
      toast.success(response.data.message, {
        position: "top-right"
      })
      navigate("/resetpassword", {state: {email: data.email}})
    } catch (error) {
      setRequestLoading(false)
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      toast.error(resMessage, {
        position: "top-right"
      })
    }
  }

  const onSubmitHandler = values => {
    verifyEmail(values)
    setEmail('')
  }
  return (
    <section className="center">
      <div className="w-full">
        <h1 className="text-4xl xl:text-6xl text-center font-[600] text-ct-yellow-600 mb-7">
          Verify Email Address
        </h1>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmitHandler)}
            className="max-w-md w-full mx-auto overflow-hidden shadow-lg bg-ct-dark-200 rounded-2xl p-8 space-y-5"
          >
            <TextInputField label="Email address" name="email" description="" {...methods.register("email")} inputWidth={300}/>
            <TextInputField
              label="Verification Code"
              name="verificationCode"
              description=""
              {...methods.register("verificationCode")}
            />
            <LoadingButton
              loading={requestLoading}
              textColor="text-ct-blue-600"
            >
              Verify Email
            </LoadingButton>
          </form>
        </FormProvider>
      </div>
    </section>
  )
}

export default EmailVerificationPage
