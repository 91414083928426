import { useEffect } from "react"
import * as yup from 'yup'
import { useForm, FormProvider } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import useStore from "../../store"
import { TextInputField } from "evergreen-ui";
import { LoadingButton } from "../../components/layout/loadbutton"
import axios from "../../backend/axios"
import {yupResolver} from "@hookform/resolvers/yup"
import { useLocation } from "react-router-dom"


const forgotPasswordSchema = yup.object().shape({
  password: yup.string().min(1, "Enter new password"),
  passwordConfirm: yup.string().min(1, "Please confirm your new password")
})

const ForgotPasswordPage = () => {
  const store = useStore()
  const navigate = useNavigate()
  const methods = useForm({
    resolver: yupResolver(forgotPasswordSchema)
  })
  const {state} = useLocation();
  const { email } = state
  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful }
  } = methods

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])

  const forgotPassword = async data => {
    try {
      store.setRequestLoading(true)
      const dataWithEmail = {...data, email}
      const response = await axios.post(
        '/resetpassword',
        dataWithEmail
      )
      store.setRequestLoading(false)
      toast.success(response.data.message, {
        position: "top-right"
      })
      navigate("/login")
    } catch (error) {
      store.setRequestLoading(false)
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      toast.error(resMessage, {
        position: "top-right"
      })
    }
  }

  const onSubmitHandler = values => {
    forgotPassword(values)
  }
  return (
    <section className="center">
      <div>
        <h1>
          Reset Password
        </h1>
        <FormProvider {...methods} >
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            
          >
            <TextInputField label="New Password" name="password" type="password" description="" {...methods.register("password")}  width="300px"/>
            <TextInputField
              label="Confirm New Password"
              name="passwordConfirm"
              type="password"
              description="" {...methods.register("passwordConfirm")}
              width="300px"
            />
            <LoadingButton
              loading={store.requestLoading}
              textColor="text-ct-blue-600"
            >
              Reset Password
            </LoadingButton>
          </form>
        </FormProvider>
      </div>
    </section>
  )
}

export default ForgotPasswordPage
