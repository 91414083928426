import { useState, useEffect } from 'react'
import * as yup from 'yup'
import {yupResolver} from "@hookform/resolvers/yup"
import { useForm, FormProvider } from "react-hook-form";
import { TextInputField } from "evergreen-ui";
import { LoadingButton } from "../../components/layout/loadbutton"
import { toast } from "react-toastify"
import { Link, useParams } from "react-router-dom"
import axios from "../../backend/axios"
import "./user.css"
import { useNavigate } from "react-router-dom"


const loginSchema = yup.object().shape({ 
  email: yup.string().email().required(),
  password: yup.string().required(),
})

const LoginPage = () => {
  const methods = useForm({
    resolver: yupResolver(loginSchema)
  })
  const { inviteId } = useParams();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [fullName, setFullName] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [requestLoading, setRequestLoading] = useState(false)


  useEffect(() => {
    setErrMsg('');
  }, [email, password, passwordConfirm, fullName])
  const navigate = useNavigate()
  const registerUser = async data => {
    try {
      setRequestLoading(true)
      const inviteData = {...data, ...{"invitationId": inviteId}}
      await axios.post("/register", inviteData)
      setRequestLoading(false)
      navigate("/login")
    } catch (error) {
      setRequestLoading(false)
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      toast.error(resMessage, {
        position: "top-right"
      })
    }
  }

  const onSubmitHandler = values => {
    registerUser(values)
    setEmail('')
    setPassword('')
    setPasswordConfirm('')
    setFullName('')
  }
  
  return (
    <section className="login">
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
        <TextInputField label="Invitation ID" name="invitationId" value={inviteId} description="" disabled {...methods.register("invitationId")}/>
            <TextInputField label="Full Name" name="fullName" description="" {...methods.register("fullName")} />
            <TextInputField label="Email" name="email" type="email" description="" {...methods.register("email")} />
            <TextInputField
              label="Password"
              name="password"
              type="password"
              description=""
              {...methods.register("password")}
            />
            <TextInputField
              label="Confirm Password"
              name="passwordConfirm"
              type="password"
              description=""
              {...methods.register("passwordConfirm")}
            />

          <LoadingButton loading={requestLoading}>Submit</LoadingButton>
            Already have an account? <Link to="/login">Login Here</Link>
        </form>
      </FormProvider>
    </div>
   </section> 
  )
}

export default LoginPage
