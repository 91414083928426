import Layout from "../components/layout/layout"
import EmailVerificationPage from "../pages/user/emailverification"
import ForgotPasswordPage from "../pages/user/resetpassword"
import LoginPage from "../pages/user/login"
import ProfilePage from "../pages/user/profile"
import RegisterPage from "../pages/user/register"
import ResetPasswordPage from "../pages/user/changepassword"
import RequireAuth from "../components/requireauth"
import OrganizationWithProviders from '../pages/org/org'
import InvoiceWithProviders from "../pages/invoice/invoice"
import Unauthorized from "../pages/user/unauthorized"
import InvoiceItemWithProviders from "../pages/invoice/invoiceitem" 
import UserWithProviders from "../pages/user/user"
import InviteWithProviders from "../pages/user/invite"
import ManageOrganizationLogo from "../pages/org/orgLogo"
import ManageOrganizationStamp from "../pages/org/orgStamp"
import ChallangeUserPage from "../pages/user/challangeuser"

const authRoutes = {
  path: "*",
  element: <Layout />,
  children: [
    {
      path: "",
      element: <RequireAuth  allowedRoles={['USER','ADMIN', 'SUPER']}/>,
      children: [
        {
          path: "unauthorized",
          children: [
            {
              path: "",
              element: <Unauthorized />
            }
          ]
        },
        {
          path: "profile",
          children: [
            {
              path: "",
              element: <ProfilePage />
            }
          ]
        },
        {
          path: "changepassword",
          children: [
            {
              path: "",
              element: <ResetPasswordPage />
            }
          ]
        },    
      ]
    },
    {
      path: "",
      element: <RequireAuth  allowedRoles={['ADMIN', 'SUPER']}/>,
      children: [
        {
          path: "user",
          children: [
            {
              path: "",
              element: <UserWithProviders />
            }
          ]
        },
        {
          path: "invite",
          children: [
            {
              path: "",
              element: <InviteWithProviders />
            }
          ]
        },
        {
          path: "profile",
          children: [
            {
              path: "",
              element: <ManageOrganizationLogo />
            }
          ]
        },
        {
          path: "profile",
          children: [
            {
              path: "",
              element: <ManageOrganizationStamp />
            }
          ]
        },
      ]
    },
    {
      path: "",
      element: <RequireAuth allowedRoles={['USER', 'ADMIN']}/>,
      children: [
        {
          path: "invoice",
          children: [
            {
              path: "",
              element: <InvoiceWithProviders />
            }
          ]
        },
        {
          path: "invoice",
          children: [
            {
              path: ":invoiceId",
              element: <InvoiceItemWithProviders />
            }
          ]
        },
      ]
    },
    {
      path: "",
      element: <RequireAuth allowedRoles={'SUPER'}/>,
      children: [
        {
          path: "org",
          children: [
            {
              path: "",
              element: <OrganizationWithProviders />
            }
          ]
        },
      ]
    },  ]
}

const normalRoutes = {
  path: "*",
  element: <Layout />,
  children: [
    {
      path: "verifyemail",
      element: <EmailVerificationPage />,
      children: [
        {
          path: ":verificationCode",
          element: <EmailVerificationPage />
        }
      ]
    },
    {
      path: "challangeuser",
      element: <ChallangeUserPage />
    },
    {
      path: "resetpassword",
      element: <ForgotPasswordPage />
    },
    {
      path: "register",
      children: [
        {
          path: ":inviteId",
          element: <RegisterPage />
        }
      ]
    },
    {
      path: "login",
      element: <LoginPage />
    },
  ]
}

const routes = [authRoutes, normalRoutes]

export default routes
