import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import useAuth from '../../hooks/useauth'
import { useNavigate } from 'react-router-dom';
import LogoutUser from '../logout';

export default function ResponsiveAppBar() {
  const { auth } = useAuth()
  const user = auth.user
  const logout = LogoutUser()
  const navigate = useNavigate()
  const signOut = async () => {
    await logout()
    navigate('/login')
  }

  return (
    <Box sx={{ flexGrow: 1, height: 100}}>
      <AppBar position="static" sx={{bgcolor: "#1abc9c", height: 100}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            EASY INVOICE
          </Typography>

          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
            margin={5}
          >          
            {!user && (
                <Link to="/login">
                  login
                </Link>
            )}
            {user && user.roles?.includes('ADMIN') && (
                  <Link href="#" onClick={() => {
                    navigate("/user")
                  }}>
                    user
                  </Link>
            )}
            {user && user.roles?.includes('ADMIN') && (
                  <Link href="#" onClick={() => {
                    navigate("/invite")
                  }}>
                    invite
                  </Link>
            )}
            {user && !user.roles?.includes('SUPER') && (
                  <Link href="#" onClick={() => {
                    navigate("/invoice")
                  }}>
                    invoice
                  </Link>
            )}
            {user && !user.roles?.includes('SUPER') && (
              <Link href="#" onClick={() => {
                navigate("/profile")
              }}>
                    profile
                  </Link>
            )}
                  {user && !user.roles?.includes('SUPER') && (
                    <Link onClick={signOut}>
                  logout </Link>
            )}
            {user && user.roles?.includes('SUPER') && (
                  <Link href="#" onClick={() => {
                    navigate("/org")
                  }}>
                    org
                  </Link>
            )}
            {user && user.roles?.includes('SUPER') && (
              <Link href="#" onClick={() => {
                navigate("/profile")
              }}>
                    profile
                  </Link>
            )}
            {user && user.roles?.includes('SUPER') && (
              <Link onClick={signOut}>logout</Link>
            )}
        </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
