import useAxiosPrivate from "../../hooks/useaxiosprivate"

import { useMemo, useState } from 'react';
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useauth";
import DownloadReport from "./report";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  // createRow, 
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip, 
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const Invoice = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
        header: 'Id',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'invoiceDate',
        header: 'Invoice Date',
        muiEditTextFieldProps: {
          type: 'date',
          required: true,
          error: !!validationErrors?.invoiceDate,
          helperText: validationErrors?.invoiceDate,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              invoiceDate: undefined,
            }),
        },
      },
      {
        accessorKey: 'dueDate',
        header: 'Due Date',
        muiEditTextFieldProps: {
          type: 'date',
          required: true,
          error: !!validationErrors?.dueDate,
          helperText: validationErrors?.dueDate,
          //remove any previous validation errors when invoice focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              dueDate: undefined,
            }),
        },
      },
      {
        accessorKey: 'from',
        header: 'From',
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.from,
          helperText: validationErrors?.from,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              from: undefined,
            }),
        },
      },
      {
        accessorKey: 'fromAddress',
        header: 'From Address',
        muiEditTextFieldProps: {
          type: 'area',
          required: false,
          error: !!validationErrors?.fromAddress,
          helperText: validationErrors?.fromAddress,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              fromAddress: undefined,
            }),
        },
      },
      {
        accessorKey: 'to',
        header: 'To',
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.to,
          helperText: validationErrors?.to,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              to: undefined,
            }),
        },
      },
      {
        accessorKey: 'toAddress',
        header: 'To Address',
        muiEditTextFieldProps: {
          type: 'area',
          required: true,
          error: !!validationErrors?.toAddress,
          helperText: validationErrors?.toAddress,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              toAddress: undefined,
            }),
        },
      },
      {
        accessorKey: 'isPaid',
        header: 'Paid',
        editVariant: 'text',
        editSelectOptions: ["Yes", "No"],
        muiEditTextFieldProps: {
          select: "false",
          error: !!validationErrors?.isPaid,
          helperText: validationErrors?.isPaid,        
        },
      },
    ],
    [validationErrors],
  );
  
  //call CREATE hook
  const { mutateAsync: createInvoice, isPending: isCreatingInvoice } =
    useCreateInvoice();
  //call READ hook
  const {
    data: fetchedInvoices = [],
    isError: isLoadingInvoicesError,
    isFetching: isFetchingInvoices,
    isLoading: isLoadingInvoices,
  } = useGetInvoices();

  //call UPDATE hook
  const { mutateAsync: updateInvoice, isPending: isUpdatingInvoice } =
    useUpdateInvoice();
  //call DELETE hook
  const { mutateAsync: deleteInvoice, isPending: isDeletingInvoice } =
    useDeleteInvoice();

  //CREATE action
  const handleCreateInvoice = async ({ values, table }) => {
    const newValidationErrors = validateInvoice(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createInvoice(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveInvoice = async ({ values, table }) => {
    const newValidationErrors = validateInvoice(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateInvoice(values);
    table.setEditingRow(null); //exit editing mode
  };
  // Open Items action
  const openInvoiceItems = (row) => {
    navigate(`/invoice/${row.original._id}`, { state: { invoiceId: row.original.id } }, {replace: true} )
  }

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Are you sure you want to delete this invoice?')) {
      deleteInvoice(row.original._id);
    }
  };
  
  const handleExportRowsCSV = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };  

  const table = useMaterialReactTable({
    columns,
    data: fetchedInvoices,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingInvoicesError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateInvoice,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveInvoice,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5">Create New Invoice</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5">Edit Invoice</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Items">
          <IconButton color="info" onClick={() => openInvoiceItems(row)}>
            <FormatListNumberedIcon />
          </IconButton>
        </Tooltip>
          <DownloadReport invoiceId={row.original._id} />
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
      sx={{
        display: 'flex',
        gap: '16px',
        padding: '8px',
        flexWrap: 'wrap',
      }}
    >
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        New Invoice
      </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRowsCSV(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All CSV
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRowsCSV(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page CSV
        </Button>
    </Box>      
    ),
    state: {
      isLoading: isLoadingInvoices,
      isSaving: isCreatingInvoice || isUpdatingInvoice || isDeletingInvoice,
      showAlertBanner: isLoadingInvoicesError,
      showProgressBars: isFetchingInvoices,
    },
  });

  return <MaterialReactTable table={table} />;
};

function useGetInvoices() {
  const axiosPrivate = useAxiosPrivate()
  return useQuery({
    queryKey: ['invoices'],
    queryFn: async () => {
        const res = await axiosPrivate.get("/invoices")
        return res.data
    },
    refetchOnWindowFocus: false,
  });
}

function useCreateInvoice() {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth()
  return useMutation({
    mutationFn: async (invoice) => {
      const invoiceWithOrg = {...invoice, ...{org: auth.user.org}}
      return await axiosPrivate.post("/addinvoice", invoiceWithOrg)
    },
    onMutate: (newInvoiceInfo) => {
      queryClient.setQueryData(['invoices'], (prevInvoices) => [
        // ...prevInvoices,
        {
          ...newInvoiceInfo,
          id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
    },    
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['invoices'] })
  });
}

function useUpdateInvoice() {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth()
  return useMutation({
    mutationFn: async (invoice) => {
      const invoiceWithOrg = {...invoice, ...{org: auth.user.org}}
      return await axiosPrivate.post("/editinvoice", invoiceWithOrg)
    },
    onMutate: (newInvoiceInfo) => {
      queryClient.setQueryData(['invoices'], (prevInvoices) =>
        prevInvoices?.map((prevInvoice) =>
          prevInvoice.id === newInvoiceInfo.id ? newInvoiceInfo : prevInvoice,
        ),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['invoices'] }),
  });
}

function useDeleteInvoice() {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate()
  return useMutation({
    mutationFn: async (invoiceId) => {
      return await axiosPrivate.post("/deleteinvoice", {id: invoiceId})
    },
    onMutate: (invoiceId) => {
      queryClient.setQueryData(['invoices'], (prevInvoices) =>
        prevInvoices?.filter((invoice) => invoice.id !== invoiceId),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['invoices'] }), //refetch invoices after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

const InvoiceWithProviders = () => (
  <QueryClientProvider client={queryClient}>
    <Invoice />
  </QueryClientProvider>
);

export default InvoiceWithProviders;

const validateRequired = (value) => !!value.length;

function validateInvoice(invoice) {
  return {
    invoiceDate: !validateRequired(invoice.invoiceDate)
      ? 'Id is Required'
      : ''
  };
}
