import useAxiosPrivate from "../../hooks/useaxiosprivate"

import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom'
import useAuth from "../../hooks/useauth";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const InvoiceItem = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const { invoiceId } = useParams()
  const { auth } = useAuth()
  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
        header: 'Id',
        enableEditing: false,
        size: 80,
      },
      {
        id: 'invoiceId',
        accessorFn: (row) => invoiceId,
        header: 'Invoice Id',
        enableEditing: false,
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          value: invoiceId,
          error: !!validationErrors?.invoiceId,
          helperText: validationErrors?.invoiceId,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              invoiceId: undefined,
            }),
        },
      },
      {
        accessorKey: 'description',
        header: 'Description',
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
          //remove any previous validation errors when invoiceItem focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              description: undefined,
            }),
        },
      },
      {
        accessorKey: 'unitPrice',
        header: 'Unit Price',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() < 0
                  ? theme.palette.error.dark
                  : theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: auth.user?.organization?.currency || 'USD'
            })}
          </Box>
        ),        
        muiEditTextFieldProps: {
          type: 'currency',
          required: true,
          error: !!validationErrors?.unitPrice,
          helperText: validationErrors?.unitPrice,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              from: undefined,
            }),
        },
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        muiEditTextFieldProps: {
          type: 'number',
          required: false,
          error: !!validationErrors?.quantity,
          helperText: validationErrors?.quantity,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              quantity: undefined,
            }),
        },
      },
      {
        accessorKey: 'taxPercent',
        header: 'Tax Percentage',
        muiEditTextFieldProps: {
          type: 'number',
          required: true,
          error: !!validationErrors?.taxPercent,
          helperText: validationErrors?.taxPercent,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              taxPercent: undefined,
            }),
        },
      },
      {
        accessorFn: (row) => ((row.unitPrice * row.quantity) + (row.unitPrice * row.quantity * row.taxPercent)/100),
        id: 'amount',
        header: 'Total Amount',
        enableEditing: false,
        Cell: ({ cell }) => (
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor:
                  cell.getValue() < 0
                    ? theme.palette.error.dark
                    : theme.palette.success.dark,
                borderRadius: '0.25rem',
                color: '#fff',
                maxWidth: '9ch',
                p: '0.25rem',
              })}
            >
              {cell.getValue()?.toLocaleString?.('en-US', {
                style: 'currency',
                currency: auth.user?.organization?.currency || 'USD'
              })}
            </Box>
          ),
        Footer: () => (
          <Stack>
            Total Amount:
            <Box color="error.main">
              {table.getPrePaginationRowModel().rows.reduce((psum, row) => psum+((row.original.unitPrice * row.original.quantity) + (row.original.unitPrice * row.original.quantity * row.original.taxPercent)/100), 0)?.toLocaleString?.('en-US', {
                style: 'currency',
                currency: auth.user?.organization?.currency || 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
        muiEditTextFieldProps: {
          type: 'currency',
          required: true,
          error: !!validationErrors?.amount,
          helperText: validationErrors?.amount,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              to: undefined,
            }),
        },
      },
      {
        accessorKey: 'isPaid',
        header: 'Paid',
        editVariant: 'text',
        editSelectOptions: ["Yes", "No"],
        muiEditTextFieldProps: {
          select: "false",
          error: !!validationErrors?.isPaid,
          helperText: validationErrors?.isPaid,        
        },
      },
    ],
    [validationErrors],
  );

  //call CREATE hook
  const { mutateAsync: createInvoiceItem, isPending: isCreatingInvoiceItem } =
    useCreateInvoiceItem();
  //call READ hook
  const {
    data: fetchedInvoiceItems = [],
    isError: isLoadingInvoiceItemsError,
    isFetching: isFetchingInvoiceItems,
    isLoading: isLoadingInvoiceItems,
  } = useGetInvoiceItems();
  //call UPDATE hook
  const { mutateAsync: updateInvoiceItem, isPending: isUpdatingInvoiceItem } =
    useUpdateInvoiceItem();
  //call DELETE hook
  const { mutateAsync: deleteInvoiceItem, isPending: isDeletingInvoiceItem } =
    useDeleteInvoiceItem();

  //CREATE action
  const handleCreateInvoiceItem = async ({ values, table }) => {
    const newValidationErrors = validateInvoiceItem(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createInvoiceItem(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveInvoiceItem = async ({ values, table }) => {
    const newValidationErrors = validateInvoiceItem(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateInvoiceItem(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm(`Are you sure you want to delete this invoiceItem? ${row.original._id}`)) {
      deleteInvoiceItem(row.original._id);
    }
  };
  const handleExportRowsCSV = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedInvoiceItems,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    enableStickyFooter: true,
    getRowId: (row) => row._id,
    muiToolbarAlertBannerProps: isLoadingInvoiceItemsError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateInvoiceItem,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveInvoiceItem,
    
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5">Create New Invoice Item</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5">Edit Invoice Item</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
    <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        New Item
      </Button>    
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRowsCSV(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All CSV
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRowsCSV(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page CSV
        </Button>      
      </Box>
    ),
    state: {
      isLoading: isLoadingInvoiceItems,
      isSaving: isCreatingInvoiceItem || isUpdatingInvoiceItem || isDeletingInvoiceItem,
      showAlertBanner: isLoadingInvoiceItemsError,
      showProgressBars: isFetchingInvoiceItems,
    },
  });

  return <MaterialReactTable table={table} />;
};

function useCreateInvoiceItem() {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth()
  return useMutation({
    mutationFn: async (invoiceItem) => {
        const invoiceItemWithOrg = {...invoiceItem, ...{org: auth.user.org}}
        return await axiosPrivate.post("/addinvoiceitem", invoiceItemWithOrg)
    },
    onMutate: (newInvoiceItemInfo) => {
      queryClient.setQueryData(['invoiceItems'], (prevInvoiceItems) => [
        ...prevInvoiceItems,
        {
          ...newInvoiceItemInfo,
          id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
    },    
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['invoiceItems'] })
  });
}

function useGetInvoiceItems() {
  const axiosPrivate = useAxiosPrivate()
  const { invoiceId } = useParams()
  return useQuery({
    queryKey: ['invoiceItems'],
    queryFn: async () => {
        const res = await axiosPrivate.get(`/invoice/${invoiceId}`)
        return res.data
    },
    refetchOnWindowFocus: false,
  });
}

function useUpdateInvoiceItem() {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth()
  return useMutation({
    mutationFn: async (invoiceItem) => {
      const invoiceItemWithOrg = {...invoiceItem, ...{org: auth.user.org}}
      return await axiosPrivate.post("/editinvoiceitem", invoiceItemWithOrg)
    },
    onMutate: (newInvoiceItemInfo) => {
      console.log(newInvoiceItemInfo)
      queryClient.setQueryData(['invoiceItems'], (prevInvoiceItems) =>
          prevInvoiceItems?.map((prevInvoiceItem) =>
          prevInvoiceItem.id === newInvoiceItemInfo.id ? newInvoiceItemInfo : prevInvoiceItem,
          ),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['invoiceItems'] }),
  })
};

function useDeleteInvoiceItem() {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate()
  return useMutation({
    mutationFn: async (invoiceItemId) => {
      return await axiosPrivate.post("/deleteinvoiceitem", {id: invoiceItemId})
    },
    onMutate: (invoiceItemId) => {
      queryClient.setQueryData(['invoiceItems'], (prevInvoiceItems) =>
        prevInvoiceItems?.filter((invoiceItem) => invoiceItem.id !== invoiceItemId),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['invoiceItems'] }),
  });
}

const queryClient = new QueryClient();

const InvoiceItemWithProviders = () => (
  <QueryClientProvider client={queryClient}>
    <InvoiceItem />
  </QueryClientProvider>
);

export default InvoiceItemWithProviders;

const validateRequired = (value) => !!value;

function validateInvoiceItem(invoiceItem) {
  return {
    quantity: !validateRequired(invoiceItem.quantity)
      ? 'Quantity is Required'
      : ''
  };
}
