import { useEffect } from "react"
import { toast } from "react-toastify"
import useAxiosPrivate from "../../hooks/useaxiosprivate"
import { useNavigate } from "react-router-dom"
import useAuth from '../../hooks/useauth'
import ManageOrganizationLogo from '../org/orgLogo.js'
import ManageOrganizationStamp from '../org/orgStamp.js'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

const ProfilePage = () => {
  const navigate = useNavigate()
  const axiosPrivate = useAxiosPrivate()
  const getUser = async () => {
    try {
      await axiosPrivate.get("/me")
    } catch (error) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      toast.error(resMessage, {
        position: "top-right"
      })
      navigate("/login")
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  const { auth } = useAuth()
  const user = auth.user

  
  return (
    <Stack
    direction="column"
    divider={<Divider orientation="vertical" flexItem />}
    spacing={1}
    margin={2}
  >          

    <Box sx={{ width: '100%', maxWidth: 500 }}>
      <Typography variant="h4" gutterBottom>
        <u>Profile Detail</u>
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        <div className="max-w-4xl mx-auto bg-ct-dark-100 rounded-md h-[20rem] flex justify-center items-center">
          <div>
            <div className="mt-8">
              <p className="mb-4">ID: {auth.user?.id}</p>
              <p className="mb-4">Name: {auth.user?.name}</p>
              <p className="mb-4">Email: {auth.user?.email}</p>
              <p className="mb-4">Roles: {auth.user?.roles.join(",")}</p>
              <p className="mb-4">Organization: {auth.user?.organization?.name}</p>
              <p className="mb-4">Currency: {auth.user?.organization?.currency || 'USD'}</p>
              {auth.user?.organization?.logo && 
              (
                <p className="mb-4">Logo: <img src={`${auth.user?.organization?.logo}`} width="250" height="250"/> 
                Relogin after uploading logo to view it</p>  
              )}
              {auth.user?.organization?.stamp && (
                <p className="mb-4">Stamp: <img src={`${auth.user?.organization?.stamp}`} width="250" height="250"/> 
                Relogin after uploading stamp to view it</p>
              )}
            </div>
          </div>
          <div>
          <Link href="#" onClick={() => {
                navigate("/changepassword")
              }}>
                    Need to reset your password? Click Here
          </Link>

          </div>
        </div>
      </Typography>
    </Box>      
    {user && user.roles?.includes('ADMIN') && (
      <Box sx={{ width: '100%', maxWidth: 500 }}>
        <div className="mt-8">
        <p>Update your logo and stamp</p>
        </div>
        <Stack direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
              >
            <ManageOrganizationLogo/>
            <ManageOrganizationStamp/>
        </Stack>
        
      </Box>
    )}
    </Stack>
  )
}


export default ProfilePage
