import create from "zustand"

const useStore = create(set => ({
  user: null,
  requestLoading: false,
  setUser: user => set(state => ({ ...state, user: user })),
  setRequestLoading: isLoading =>
    set(state => ({ ...state, requestLoading: isLoading }))
}))

export default useStore
