import { useState, useEffect } from 'react'
import * as yup from 'yup'
import {yupResolver} from "@hookform/resolvers/yup"
import { useForm, FormProvider } from "react-hook-form";
import { TextInputField } from "evergreen-ui";
import { LoadingButton } from "../../components/layout/loadbutton"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import axios from "../../backend/axios"
import "./user.css"

const challangeEmailSchema = yup.object().shape({ 
  email: yup.string().email().required()
})

const ChallangeUserPage = () => {
  const navigate = useNavigate()
  const [requestLoading, setRequestLoading] = useState(false)
  const methods = useForm({
    resolver: yupResolver(challangeEmailSchema)
  })

  const [email, setEmail] = useState('')
  useEffect(() => {}, [email])

  const challangeUser = async data => {
    try {
      setRequestLoading(true)
      const response = await axios.post("/sendopt", data)
      setRequestLoading(false)
      toast.success(response.data.message, {
        position: "top-right"
      })
      navigate("/verifyemail")
    } catch (error) {
      setRequestLoading(false)
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      toast.error(resMessage, {
        position: "top-right"
      })
    }
  }

  const onSubmitHandler = values => {
    challangeUser(values)
    setEmail('')
  }
  return (
    <section className="center">
      <div>
        <h2>Provide your email so we can verify your identity</h2>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
            <TextInputField label="Email" name="email" type="email" description="" {...methods.register("email")}/>
            <LoadingButton loading={requestLoading}>
              Submit
            </LoadingButton>
          </form>
        </FormProvider>
      </div>
    </section>
  )
}

export default ChallangeUserPage
