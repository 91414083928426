import useAxiosPrivate from "../../hooks/useaxiosprivate"
import { toast } from "react-toastify"
import { useMemo, useState } from 'react';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Organization = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
        header: 'Id',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Org Name',
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        muiEditTextFieldProps: {
          type: 'email',
          required: true,
          error: !!validationErrors?.email,
          helperText: validationErrors?.email,
          //remove any previous validation errors when org focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              email: undefined,
            }),
        },
      },
      {
        accessorKey: 'addressLine1',
        header: 'Address Line1',
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.addressLine1,
          helperText: validationErrors?.addressLine1,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              addressLine1: undefined,
            }),
        },
      },
      {
        accessorKey: 'addressLine2',
        header: 'Address Line2',
        muiEditTextFieldProps: {
          type: 'text',
          required: false,
          error: !!validationErrors?.addressLine2,
          helperText: validationErrors?.addressLine2,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              addressLine2: undefined,
            }),
        },
      },
      {
        accessorKey: 'city',
        header: 'City',
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.city,
          helperText: validationErrors?.city,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              city: undefined,
            }),
        },
      },
      {
        accessorKey: 'state',
        header: 'State',
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.state,
          helperText: validationErrors?.state,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              state: undefined,
            }),
        },
      },
      {
        accessorKey: 'zipcode',
        header: 'Postal Code',
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.zipcode,
          helperText: validationErrors?.zipcode,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              zipcode: undefined,
            }),
        },
      },
      {
        accessorKey: 'country',
        header: 'Country Code',
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.country,
          helperText: validationErrors?.country,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              country: undefined,
            }),
        },
      },
      {
        accessorKey: 'currency',
        header: 'Currency',
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.country,
          helperText: validationErrors?.country,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              country: undefined,
            }),
        },
      },
      {
        accessorKey: 'primaryPhone',
        header: 'Primary Phone',
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.primaryPhone,
          helperText: validationErrors?.primaryPhone,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              primaryPhone: undefined,
            }),
        },
      },
      {
        accessorKey: 'secondaryPhone',
        header: 'Secondary Phone',
        muiEditTextFieldProps: {
          type: 'text',
          required: false,
          error: !!validationErrors?.secondaryPhone,
          helperText: validationErrors?.secondaryPhone,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              secondaryPhone: undefined,
            }),
        },
      },

      {
        accessorKey: 'website',
        header: 'Website',
        muiEditTextFieldProps: {
          type: 'text',
          required: false,
          error: !!validationErrors?.website,
          helperText: validationErrors?.website,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              website: undefined,
            }),
        },
      }
    ],
    [validationErrors],
  );

  //call CREATE hook
  const { mutateAsync: createOrg, isPending: isCreatingOrg } =
    useCreateOrg();
  //call READ hook
  const {
    data: fetchedOrgs = [],
    isError: isLoadingOrgsError,
    isFetching: isFetchingOrgs,
    isLoading: isLoadingOrgs,
  } = useGetOrgs();
  //call UPDATE hook
  const { mutateAsync: updateOrg, isPending: isUpdatingOrg } =
    useUpdateOrg();
  //call DELETE hook
  const { mutateAsync: deleteOrg, isPending: isDeletingOrg } =
    useDeleteOrg();

  //CREATE action
  const handleCreateOrg = async ({ values, table }) => {
    const newValidationErrors = validateOrg(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createOrg(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveOrg = async ({ values, table }) => {
    const newValidationErrors = validateOrg(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateOrg(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Are you sure you want to delete this org?')) {
      deleteOrg(row.original._id);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedOrgs,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingOrgsError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateOrg,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveOrg,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5">Create New Org</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Edit Org</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Create New Org
      </Button>
    ),
    state: {
      isLoading: isLoadingOrgs,
      isSaving: isCreatingOrg || isUpdatingOrg || isDeletingOrg,
      showAlertBanner: isLoadingOrgsError,
      showProgressBars: isFetchingOrgs,
    },
  });

  return <MaterialReactTable table={table} />;
};

function useCreateOrg() {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate()
  return useMutation({
    mutationFn: async (org) => {
      try{
        return await axiosPrivate.post("/addorg", org)
      }catch(err){
        toast.error(err.response.data.message)
      }
    },
    onMutate: (newOrgInfo) => {
      queryClient.setQueryData(['orgs'], (prevOrgs) => [
        ...prevOrgs || [],
        {
          ...newOrgInfo,
          id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['orgs'] })
  });
}

function useGetOrgs() {
  const axiosPrivate = useAxiosPrivate()
  return useQuery({
    queryKey: ['orgs'],
    queryFn: async () => {
        const res = await axiosPrivate.get("/orgs")
        return res.data
    },
    refetchOnWindowFocus: false,
  });
}

function useUpdateOrg() {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate()
  return useMutation({
    mutationFn: async (org) => {
      return await axiosPrivate.post("/editorg", org)
    },
    onMutate: (newOrgInfo) => {
      queryClient.setQueryData(['orgs'], (prevOrgs) =>
        prevOrgs?.map((prevOrg) =>
          prevOrg.id === newOrgInfo.id ? newOrgInfo : prevOrg,
        ),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['orgs'] }),
  });
}

function useDeleteOrg() {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate()
  return useMutation({
    mutationFn: async (orgId) => {
      return await axiosPrivate.post("/deleteorg", {_id: orgId})
    },
    onMutate: (orgId) => {
      queryClient.setQueryData(['orgs'], (prevOrgs) =>
        prevOrgs?.filter((org) => org.id !== orgId),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['orgs'] }), //refetch orgs after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

const OrganizationWithProviders = () => (
  <QueryClientProvider client={queryClient}>
    <Organization />
  </QueryClientProvider>
);

export default OrganizationWithProviders;

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

function validateOrg(org) {
  return {
    name: !validateRequired(org.name)
      ? 'Name is Required'
      : '',
    email: !validateEmail(org.email) ? 'Incorrect Email Format' : '',
  };
}
