import axios from "../backend/axios"
import useAuth from "./useauth"
const useRefreshToken = () => {
    const {setAuth} = useAuth()

    const refresh = async () => {
        const response = await axios.get("/refresh", {
            withCredentials: true
        });
        setAuth(prev => {
            return { ...prev, accessToken: response.data.access_token}
        });
        return response.data.access_token;
    }
    return refresh
}

export default useRefreshToken;