import * as React from 'react';
import useAxiosPrivate from "../../hooks/useaxiosprivate"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import Button from '@mui/material/Button';
// import jsPDFInvoiceTemplate, {OutputType} from "jspdf-invoice-template-nodejs";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import useAuth from '../../hooks/useauth'

export default function DownloadReport({ invoiceId }) {
  const [open, setOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth()
  const user = auth.user?.name
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  }
  const handleDownload = async () => {
    try {
        const res = await axiosPrivate.post(`/reportinvoice/${invoiceId}`)
        const data = res.data
        var company_logo = {
            w: 30,
            h: 25
          };
          var comapnyJSON={
            companyName: data.organization[0].name,
            companyAddress: `${data.organization[0].addressLine1} ${data.organization[0].addressLine2} \n${data.organization[0].city} ${data.organization[0].state}, ${data.organization[0].zipcode}`,
            companyCity: data.organization[0].city,
            companyState: data.organization[0].state,
            companyZipcode: data.organization[0].zipcode,
            companyCountry: data.organization[0].country,
            companyEmail: data.organization[0].email,
            companyPhone: `${data.organization[0].primaryPhone}/ ${data.organization[0].secondaryPhone}`,
            companyWebsite: data.organization[0]?.website,
          };
          
          var customer_BillingInfoJSON={
            customerName: data.to,
            customerAddress: data.toAddress
          };
          
          
          var invoiceJSON={
            invoiceNo: data._id,
            invoiceDate: data.invoiceDate,
            invoiceGenerationDate: data.createdAt,
            invoicePaid: data.isPaid,
            totalAmnt:'Rs.1,24,200'
          }
          var logoData = data.organization[0].logo;
          var stamp = data.organization[0].stamp;
          var fontSizes={
            HeadTitleFontSize:18,
            Head2TitleFontSize:16,
            TitleFontSize:14,
            SubTitleFontSize:12,
            NormalFontSize:10,
            SmallFontSize:8
          };
          
          var lineSpacing={
            NormalSpacing:12,
          };
        var doc = new jsPDF();
        var rightStartCol1=20;
        var rightStartCol2=60;
        var InitialstartX=40;
        var startX=20;
        var InitialstartY=20;
        var startY=20;
        var lineHeights=12;
        doc.setFontSize(fontSizes.SubTitleFontSize);
        doc.setFont("helvetica", "bold");
        doc.addImage(logoData,'PNG',startX,startY+=10,company_logo.w,company_logo.h);
            doc.text(comapnyJSON.companyName,startX, startY+=15+company_logo.h,'left');

        doc.text("Address: ", startX, startY+=lineSpacing.NormalSpacing);
        doc.setFont("helvetica", "normal");
        doc.text(comapnyJSON.companyAddress, 80, startY);

        doc.setFont("helvetica", "bold");
        doc.text("Email: ", startX, startY+=lineSpacing.NormalSpacing);
        doc.setFont("helvetica", "normal");
        doc.text(comapnyJSON.companyEmail, 80, startY);

        doc.setFont("helvetica", "bold");
        doc.text("Phone: ", startX, startY+=lineSpacing.NormalSpacing);
        doc.setFont("helvetica", "normal");
        doc.text(comapnyJSON.companyPhone, 80, startY);

        var tempY=InitialstartY;
        doc.setFont("helvetica", "bold");
        doc.text("Invoice No: ",rightStartCol1+80,tempY+=lineSpacing.NormalSpacing);
        doc.setFont("helvetica", "normal");
        doc.text(invoiceJSON.invoiceNo, rightStartCol2+80, tempY);
        doc.setFont("helvetica", "bold");
        doc.text("Invoice Date: ",  rightStartCol1+80, tempY+=lineSpacing.NormalSpacing);
        doc.setFont("helvetica", "normal");
        doc.text(invoiceJSON.invoiceDate,rightStartCol2+80, tempY);
        doc.setFont("helvetica", "bold");
        doc.text("Invoice Gem Date: ",rightStartCol1+80,tempY+=lineSpacing.NormalSpacing);
        doc.setFont("helvetica", "normal");
        doc.text(invoiceJSON.invoiceGenerationDate, rightStartCol2+80, tempY);
        
        //-------Customer Info Billing---------------------
        var startBilling=startY+tempY;
        doc.setFont("helvetica", "bold");
        doc.text("Customer Name: ",startX, startY+=lineSpacing.NormalSpacing);
        doc.setFont("helvetica", "normal");
        doc.text(customer_BillingInfoJSON.customerName, startX, startY+=lineSpacing.NormalSpacing);
        doc.setFont("helvetica", "bold");
        doc.text("Customer Address", startX, startY+=lineSpacing.NormalSpacing);
        doc.setFont("helvetica", "normal");
        doc.text(customer_BillingInfoJSON.customerAddress, 80, startY);
        var rightcol2=400;
        startY=startBilling;

        var header = function(data) {
            doc.setFont("helvetica", "bold", 8);
            doc.setTextColor(40);
        };
        doc.setFont("helvetica", "normal");
        var options = {
            beforePageContent: header,
            margin: {
                top: 150 
            },
            styles: {
                overflow: 'linebreak',
                fontSize: 8,
                rowHeight: 'auto',
                columnWidth: 'wrap'
            },
            columnStyles: {
                1: {columnWidth: 'auto'},
                2: {columnWidth: 'auto'},
                3: {columnWidth: 'auto'},
                4: {columnWidth: 'auto'},
                5: {columnWidth: 'auto'},
                6: {columnWidth: 'auto'},
            },
            startY: startY+=50
            };
        var columns = [
            {title: "ID", dataKey: "id",width: 90},
            {title: "Description", dataKey: "description",width: 40}, 
            {title: "Unit Price", dataKey: "unitPrice",width: 40}, 
            {title: "Quantity", dataKey: "quantity",width: 40}, 
            {title: "Amount", dataKey: "amount",width: 40}, 
        ];
        var rows = data.items;
        doc.autoTable({columns: columns, body: rows, margin: {top:150}});
        //-------Invoice Footer---------------------
        var rightcol1=340;
        var rightcol2=20;
        
        startY=doc.lastAutoTable.finalY+30;
        doc.setFont("helvetica", "normal", 8);
        doc.setFont("helvetica", "bold");
        doc.text('For '+comapnyJSON.companyName+',',rightcol2, startY+=lineSpacing.NormalSpacing);

        doc.text('------------------------------------',rightcol2, startY+=lineSpacing.NormalSpacing+4);
        doc.text('Authorised Signatory',rightcol2, startY+5);
        doc.text('-------------------------------',rightcol2, startY+=lineSpacing.NormalSpacing+10);
        doc.text('Date',rightcol2, startY+5);

        doc.addImage(stamp,'PNG',startX,startY+=10,company_logo.w,company_logo.h);

        doc.save(`Invoice_${invoiceJSON.invoiceNo}.pdf`);

        setOpen(false);
        } catch (error) {
            console.log(error)
        }
  }

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen}>
      <DownloadForOfflineOutlinedIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            Download Report for
            <br></br>
            Invoice: ${invoiceId}
        </DialogTitle>
        <DialogActions>
          <Button center="true" variant="outlined" disableElevation onClick={handleDownload}>
              Download Report
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
