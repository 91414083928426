import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { InputLabel } from '@mui/material';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import useAuth from '../../hooks/useauth'
import useAxiosPrivate from "../../hooks/useaxiosprivate"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function ManageOrganizationStamp() {
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState()
  const [errorMsg, setErrorMsg] = React.useState(false)
  const [isSuccess, setIsSuccess] = React.useState(false)

  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth()
  const user = auth.user

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }
  const handleUpdate = () => {
    var reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = async () => {
      try {
        await axiosPrivate.post("/updatestamp", {id: user.org, stamp: reader.result})
      } catch (error) {
        setErrorMsg(error.data)
      }
    };
    reader.onerror = error => {
      setErrorMsg(`Error: , ${error}`);
    };
    handleClose()
  };

  const handleFileChange = (event) => {
    setErrorMsg("")
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  }
  const validateFile = () => {
    const MIN_FILE_SIZE = 20
    const MAX_FILE_SIZE = 3072

    if (!selectedFile) {
      setErrorMsg("Please choose a file")
      setIsSuccess(false)
      return;
    }
    const extension = selectedFile.name.substring(selectedFile.name.lastIndexOf(".")+1)

    if (extension.toLowerCase()!=="png") {

      setErrorMsg("Invalid file format, only PNG files allowed")
      setIsSuccess(false)
      return
    }
    const fileSizeKB = selectedFile.size / 1024;
    if (fileSizeKB < MIN_FILE_SIZE || fileSizeKB > MAX_FILE_SIZE) {
      setErrorMsg("Invalid file size. Should be > 1MB and < 3MB")
      setIsSuccess(false)
      return
    }
    setErrorMsg("Image is valid!!!")
    setIsSuccess(true)
  }

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} variant="contained" disableElevation>
        Change Stamp
      </Button>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Manage your organization"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add Stamp to your organization
            <br/><br/>
          </DialogContentText>
          <form>
            <Stack spacing={2}>
            <Item>
            <InputLabel>Choose your Stamp 250 x 100 pixels and less than 3MB</InputLabel>
            <Stack direction="row" spacing={1}>
            <TextField type="file" onChange={handleFileChange}/>
            <Button variant="contained" color="primary" component="span" onClick={validateFile}>
              Validate
          </Button>
            </Stack>
            </Item>
            {errorMsg && !isSuccess && (<Alert severity="error">{errorMsg}</Alert>)}
            {errorMsg && isSuccess && (<Alert severity="success">{errorMsg}</Alert>)}
            </Stack>
          </form>     
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" component="span" onClick={handleUpdate}>
              Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
