// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height:60vh;
  }

.container {
    display: flex;
}

.forgotpassword{
    margin-right: auto;
}

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height:60vh;
  }`, "",{"version":3,"sources":["webpack://./src/pages/user/user.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;EACb;;AAEF;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;EACb","sourcesContent":[".center {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height:60vh;\n  }\n\n.container {\n    display: flex;\n}\n\n.forgotpassword{\n    margin-right: auto;\n}\n\n.login {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height:60vh;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
