import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { AuthProvider } from "./context/authprovider.js"

// import { disableReactDevTools } from 'disable-react-devtools'

// if (process.env.NODE_ENV === 'production') {
//   disableReactDevTools()
// }
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
      <ToastContainer />
    </BrowserRouter>
  </React.StrictMode>
)
