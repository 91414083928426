import { Outlet } from "react-router-dom"
// import Header from "./header"
import ResponsiveAppBar from "./header"
import './layout.css'

function Layout() {
    return (
        <div>
            <header>
                <ResponsiveAppBar />
            </header>
            <main>
                <Outlet />
            </main>
        </div>
    )
}
export default Layout