import axios from '../backend/axios'
import useAuth from '../hooks/useauth'

const LogoutUser = () => {
    const { setAuth } = useAuth();
    const logout = async () => {
    setAuth({});
        try {
            await axios.get('/logout')
        } catch (err) {
            console.error(err)
        }
    }
    return logout
};

export default LogoutUser
