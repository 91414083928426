import axios from "axios";

const base_uri = `${process.env.REACT_APP_BASE_URL}/api`;

export default axios.create({
  baseURL: base_uri,
  headers: {'Content-Type': 'application/json'},
  withCredentials: true,
});

export const axiosPrivate = axios.create({
  baseURL: base_uri,
  withCredentials: true,
  headers: {'Content-Type': 'application/json'}
})
// userApi.defaults.headers.common["Content-Type"] = "application/json";

// export const refreshAccessTokenFn = async () => {
//   const response = await userApi.get<ILoginResponse>('auth/refreshToken');
//   return response.data;
// };

// userApi.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;
//     const errMessage = error.response.data.message as string;
//     if (errMessage.includes('not logged in') && !originalRequest._retry) {
//       originalRequest._retry = true;
//       await refreshAccessTokenFn();
//       return userApi(originalRequest);
//     }
//     if (error.response.data.message.includes('not refresh')) {
//       document.location.href = '/login';
//     }
//     return Promise.reject(error);
//   }
// );