import { Button, Spinner } from 'evergreen-ui'

export const LoadingButton = ({
  textColor = "text-white",
  btnColor = "bg-ct-yellow-600",
  children,
  loading = false,
}) => {
  return (
    <Button type="submit" marginRight={16} intent="success">
    {
    loading ? (
      <div className="flex items-center gap-3">
        <Spinner />
      </div>
    ) : (
      <span className={`${textColor}`}>{children}</span>
    )}
    </Button>
  );
};