// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    padding: 60px;
    text-align: center;
    background: #1abc9c;
    color: white;
    font-size: 20px;
  }

ul {
    width: 50%;
    list-style-type: none;
    margin: auto;
    white-space: nowrap;
}
.nav {
    /* border-bottom:1px solid #ccc; */
    list-style:none;
    margin:0;
    padding:0;
    text-align: center;
    padding-bottom: 30px;
    width : 500px;
    margin : auto;
    overflow : hidden;
    float: right;
}
.nav li {
    display:block;
    float : left;
    text-align: center;
    padding: 20;
    width : 23.33%;
}
.nav a {
    display:inline;
    text-decoration: none;
    color: #999;
    border-bottom: 1px solid currentColor;
    padding-bottom : 5px;
}
.nav a:hover {
    color: #0088cc;
}

main {
    max-width: 1800px;
    margin: 30px auto;
}

.forminput {
    width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/layout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,eAAe;EACjB;;AAEF;IACI,UAAU;IACV,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,kCAAkC;IAClC,eAAe;IACf,QAAQ;IACR,SAAS;IACT,kBAAkB;IAClB,oBAAoB;IACpB,aAAa;IACb,aAAa;IACb,iBAAiB;IACjB,YAAY;AAChB;AACA;IACI,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,cAAc;AAClB;AACA;IACI,cAAc;IACd,qBAAqB;IACrB,WAAW;IACX,qCAAqC;IACrC,oBAAoB;AACxB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".header {\n    padding: 60px;\n    text-align: center;\n    background: #1abc9c;\n    color: white;\n    font-size: 20px;\n  }\n\nul {\n    width: 50%;\n    list-style-type: none;\n    margin: auto;\n    white-space: nowrap;\n}\n.nav {\n    /* border-bottom:1px solid #ccc; */\n    list-style:none;\n    margin:0;\n    padding:0;\n    text-align: center;\n    padding-bottom: 30px;\n    width : 500px;\n    margin : auto;\n    overflow : hidden;\n    float: right;\n}\n.nav li {\n    display:block;\n    float : left;\n    text-align: center;\n    padding: 20;\n    width : 23.33%;\n}\n.nav a {\n    display:inline;\n    text-decoration: none;\n    color: #999;\n    border-bottom: 1px solid currentColor;\n    padding-bottom : 5px;\n}\n.nav a:hover {\n    color: #0088cc;\n}\n\nmain {\n    max-width: 1800px;\n    margin: 30px auto;\n}\n\n.forminput {\n    width: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
