import { useEffect } from "react"
import * as yup from 'yup'
import { useForm, FormProvider } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import useStore from "../../store"
import { TextInputField } from "evergreen-ui";
import { LoadingButton } from "../../components/layout/loadbutton"
import useAxiosPrivate from "../../hooks/useaxiosprivate"
import {yupResolver} from "@hookform/resolvers/yup"
import useAuth from "../../hooks/useauth"


const resetPasswordSchema = yup.object().shape({
  oldPassword: yup.string().min(1, "Enter current password"),
  password: yup.string().min(1, "Enter new password"),
  passwordConfirm: yup.string().min(1, "Please confirm your new password")
})

const ResetPasswordPage = () => {
  const axiosPrivate = useAxiosPrivate()
  const store = useStore()
  const navigate = useNavigate()
  const { auth } = useAuth()

  const methods = useForm({
    resolver: yupResolver(resetPasswordSchema)
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful }
  } = methods

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])

  const resetPassword = async data => {
    try {
      store.setRequestLoading(true)
      const datawithOrg = {...data, ...{org: auth.user.org}, ...{email: auth.user.email}}
      const response = await axiosPrivate.patch(
        '/resetpassword',
        datawithOrg
      )
      store.setRequestLoading(false)
      toast.success(response.data.message, {
        position: "top-right"
      })
      navigate("/profile")
    } catch (error) {
      store.setRequestLoading(false)
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      toast.error(resMessage, {
        position: "top-right"
      })
    }
  }

  const onSubmitHandler = values => {
    resetPassword(values)
  }
  return (
    <section className="center">
      <div>
        <h1>
          Reset Password
        </h1>
        <FormProvider {...methods} >
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            
          >
            <TextInputField label="Current Password" name="oldPassword" type="password" description="" {...methods.register("oldPassword")} width="300px"/>
            <TextInputField label="New Password" name="password" type="password" description="" {...methods.register("password")}  width="300px"/>
            <TextInputField
              label="Confirm New Password"
              name="passwordConfirm"
              type="password"
              description="" {...methods.register("passwordConfirm")}
              width="300px"
            />
            <LoadingButton
              loading={store.requestLoading}
              textColor="text-ct-blue-600"
            >
              Reset Password
            </LoadingButton>
          </form>
        </FormProvider>
      </div>
    </section>
  )
}

export default ResetPasswordPage
