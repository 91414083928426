import useAxiosPrivate from "../../hooks/useaxiosprivate"

import { useMemo, useState } from 'react';
import useAuth from "../../hooks/useauth";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import DeleteIcon from '@mui/icons-material/Delete';

const Invite = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const { auth } = useAuth()
  
  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
        header: 'Id',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'org',
        header: 'Organization',
        enableEditing: false,
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          value: auth.user.org,
          error: !!validationErrors?.org,
          helperText: validationErrors?.org,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              org: undefined,
            }),
        },
      },
      {
        accessorKey: 'invitee',
        header: 'Invitee',
        muiEditTextFieldProps: {
          type: 'email',
          required: true,
          error: !!validationErrors?.invitee,
          helperText: validationErrors?.invitee,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              invitee: undefined,
            }),
        },
      },
      {
        accessorKey: 'roles',
        header: 'Roles',
        muiEditTextFieldProps: {
          type: 'string',
          required: true,
          error: !!validationErrors?.roles,
          helperText: validationErrors?.roles,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              roles: undefined,
            }),
        },
      },
    ],
    [validationErrors],
  );

  // create Hook
  const { mutateAsync: createInvite, isPending: isCreatingInvite } =
    useCreateInvite();

  //call READ hook
  const {
    data: fetchedInvites = [],
    isError: isLoadingInvitesError,
    isFetching: isFetchingInvites,
    isLoading: isLoadingInvites,
  } = useGetInvites();
  //call DELETE hook
  const { mutateAsync: deleteInvite, isPending: isDeletingInvite } =
    useDeleteInvite();

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Are you sure you want to delete this invite?')) {
      deleteInvite(row.original._id);
    }
  };

  const handleCreateInvite = async ({ values, table }) => {
    const newValidationErrors = validateInvite(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createInvite(values);
    table.setCreatingRow(null); //exit creating mode
  };
  
  const table = useMaterialReactTable({
    columns,
    data: fetchedInvites,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingInvitesError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateInvite,
    onEditingRowCancel: () => setValidationErrors({}),

    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Edit Invite</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Invite New User
      </Button>
    ),

    state: {
      isLoading: isLoadingInvites,
      isSaving: isDeletingInvite,
      showAlertBanner: isLoadingInvitesError,
      showProgressBars: isFetchingInvites,
    },
  });

  return <MaterialReactTable table={table} />;
};

function useCreateInvite() {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth()
  return useMutation({
    mutationFn: async (invite) => {
      const inviteWithOrg = {...invite, ...{org: auth.user.org}, ...{invitor: auth.user.email}}
      return await axiosPrivate.post("/addinvite", inviteWithOrg)
    },
    onMutate: (newInviteInfo) => {
      queryClient.setQueryData(['invites'], (prevInvites) => [
        // ...(prevInvites),
        {
          ...newInviteInfo,
          id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
    },    
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['invites'] })
  });
}

function useGetInvites() {
  const axiosPrivate = useAxiosPrivate()
  return useQuery({
    queryKey: ['invites'],
    queryFn: async () => {
        const res = await axiosPrivate.get("/invites")
        return res.data
    },
    refetchOnWindowFocus: false,
  });
}

function useDeleteInvite() {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate()
  return useMutation({
    mutationFn: async (inviteId) => {
      return await axiosPrivate.post("/deleteinvite", {id: inviteId})
    },
    onMutate: (inviteId) => {
      queryClient.setQueryData(['invites'], (prevInvites) =>
        prevInvites?.filter((invite) => invite.id !== inviteId),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['invites'] }), //refetch invites after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

const InviteWithProviders = () => (
  <QueryClientProvider client={queryClient}>
    <Invite />
  </QueryClientProvider>
);

export default InviteWithProviders;

const validateRequired = (value) => !!value.length;

function validateInvite(invite) {
  return {
    invitee: !validateRequired(invite.invitee)
      ? 'Id is Required'
      : ''
  };
}
